'use client';

import { useEffect } from 'react';

import { isTestEnvironment } from '~/global/global.constants';
import { ErrorWrapper } from '~/app/ErrorWrapper';

export type Props = {
	error: Error & { digest?: string }
	reset?: () => void
};

export default function Error({ error, reset }: Props) {
	useEffect(() => {
		console.error(error);
	}, []);

	return (
		<ErrorWrapper inline={false} error={error} reset={reset} showStackTrace={isTestEnvironment} />
	);
}
